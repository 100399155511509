.wrapperAddTask {
	padding: 5px 0;
	background-color: white;
	border-radius: 5px;
}

.input {
	width: 40vw;
	font-size: 16px;
	line-height: 120%;
}

.input input {
	width: 100%;
}


.plusAddTask {
	margin-right: 10px;
	padding: 7px 0;
	padding-left: 5px;
	font-size: 16px;
}

.inputSearch {
	width: 45vw;
	margin-bottom: 15px;
	padding: 5px;

	opacity: 1;
	border: 2px solid black;
	border-radius: 5px;
}

.inputSearch input {
	width: 100%;
}

.inputSearch:hover {
	box-shadow: 0 3px 10px rgb(0 0 0 / 60%);
}

.removeButton {
	cursor: pointer;

	position: absolute;
	top: 5px;
	right: 3px;

	font-size: 20px;

	opacity: 0.3;
}

.removeButton:hover {
	opacity: 1;
}
