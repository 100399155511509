.form {
	position: relative;
	display: inline-block;
}

.input input {
	display: inline-block;

	width: 100%;
	padding: 5px 0;

	opacity: 0.4;
	border: none;
}

.form::placeholder {
	color: black;
	opacity: 0.5;
}

.input:focus,
.input:hover {
	color: black;
	opacity: 1;
}

.form:hover {
	opacity: 0.8;
}
