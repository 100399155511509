.task {
	cursor: pointer;

	position: relative;

	margin-bottom: 5px;
	padding: 5px 0;

	background-color: white;
	border-radius: 5px;
}

.titleWrapper {
	width: 90%;
	margin-right: 5px;
	padding: 3px;
	padding-right: 15px;
	padding-left: 5px;

	background-color: white;
	border-radius: 5px;
}

.subtask {
	margin-bottom: 2px;
	padding-left: 26px;
}

.task:hover {
	background-color: #ccc;
}

.task:hover .titleWrapper {
	background-color: #ccc;
}

.task:hover .subtask {
	background-color: #ccc;
}

.task:hover .subtask:hover {
	background-color: white;
}

.subtask:hover .titleWrapper {
	background-color: #ccc;
}

.subtask:hover>.titleWrapper {
	background-color: white;
}

.title {
	cursor: pointer;
	margin-right: 10px;
}

.checkbox {
	margin-right: 10px;
}

.caret {
	cursor: default;
	margin-right: 10px;
}

.stop {
	cursor: default;
	margin-right: 10px;
}


.plusAddSubtask {
	margin-right: 10px;
	padding-left: 30px;
}

.input {
	padding: 0;
	font-size: 14px;
}

.inputSubtask {
	padding: 5px 0;
	font-size: 14px;
}

.plusAddTask {
	margin-right: 10px;
}

.plusAddTask:hover svg {
	fill: #1677ff
}

.removeButton {
	position: absolute;
	top: 8px;
	right: 5px;

	font-size: 20px;

	opacity: 0.3;
}

.removeButton:hover {
	opacity: 1;
}
