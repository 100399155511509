.title {
	margin-bottom: 10px;
}

.text {
	resize: none;

	display: block;

	width: 100%;
	height: 100%;

	outline: none;
}
